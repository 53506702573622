<template>
  <div class="sign">
    <div ref="pdf" class="sign-pdf" @click.self="show = false">
      <pdf ref="pdfs" v-for="i in numPages" :src="tmpDocUrl" :key="i" :page="i"></pdf>
    </div>
  </div>
</template>

<script>
import { viewSignature } from "@/util/api";
import { mapState, mapMutations } from "vuex";
import pdf from "vue-pdf";
export default {
  name: 'sign',
  components: {
    pdf
  },
  data() {
    return {
      tmpDocUrl: "",
      numPages: 1,
    };
  },
  computed: {
    ...mapState(["signatureId", 'linkCodeInfo', 'userInfo', 'sign', 'signatureDetails']),
  },
  watch: {
    $route() {
      if (this.$route.path == '/details') {
        this.viewSignature()
      }
    },
  },
  methods: {
    ...mapMutations(['setSignatureDetails']),
    viewSignature () {
      let originFlag = true
      if (this.$route.query.val == 'false') {
        originFlag = false
      }
      viewSignature({id: this.signatureDetails.id, originFlag: originFlag }).then((res) => {
        // let CMAP_URL = "https://unpkg.com/pdfjs-dist@2.0.943/cmaps/";
        let CMAP_URL = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/"
        var loadingTask = pdf.createLoadingTask({
          url: res,
          cMapUrl: CMAP_URL,
          cMapPacked: true,
        });
        console.log(loadingTask)
        this.tmpDocUrl = loadingTask
        this.tmpDocUrl.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
      })
    }
  },
  mounted() {
    this.viewSignature()
  },
};
</script>

<style lang="less" scoped>
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-pdf {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    &-btn {
      position: absolute;
      width: 83px;
      height: 38px;
      background: rgba(0, 103, 225, 0.4);
      // border: 2px dashed #0067e1;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      z-index: 11;
      justify-content: center;
      align-items: center;
      &-span {
        white-space: nowrap;
        font-size: 12px;
      }
      &-img {
        // height: 38px;
        // max-width: 80px;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-sign {
    width: 100%;
    height: 66px;
  }
  &-btn {
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255);
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.04);

    position: fixed;
    left: 0;
    bottom: 0;
    opacity: 1;
    z-index: 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    &-pos {
      width: 120px;
      height: 44px;
      background: rgba(246, 247, 248);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #464646;
      opacity: 1;
      text-align: center;
      position: relative;
      &-num {
        width: 16px;
        height: 16px;
        background: #E34D59;
        border-radius: 50%;
        display: inline-block;

        color: #FFFFFF;
        line-height: 16px;
        text-align: center;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;

        position: absolute;
        top: 0;
        right: 0;
      }
    }
    &-enclosure {
      width: 88px;
      height: 44px;
      background: rgba(246, 247, 248);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #464646;
      opacity: 1;
      text-align: center;
      position: relative;
    }
    &-sign {
      width: 108px;
      height: 44px;
      background: rgba(0, 103, 225);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #ffffff;
      text-align: center;
    }
    &-sign1 {
      width: 108px;
      height: 44px;
      background: rgba(246, 247, 248);
      opacity: 1;
      border-radius: 22px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #464646;
      text-align: center;
    }
  }
  &-popup {
    padding: 20px;
    height: 320px;
    display: flex;
    flex-direction: column;
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 16px;
      &-text {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #30586c;
        opacity: 1;
      }
      &-img {
        width: 28px;
        height: 18px;
      }
    }
    &-con {
      flex: 1;
      // display: flex;
      // flex-direction: column;
      overflow: auto;
      &-o {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 18px 0 18px;
        &-select {
          background: #F6F7F8;
        }
      }
      &-d {
        width: 20px;
        height: 20px;
      }
      &-i {
        height: 60px;
        &-box {
          flex: 1;
          display: flex;
        }
      }
      &-title {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #30586C;
          opacity: 1;
          text-align: center;
        }
      }
      &-div {
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 48px;
        color: #464646;
        opacity: 1;
        text-align: center;
        &-btn {
          width: 100%;
          height: 44px;
          background: #0067E1;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 44px;
          color: #FFFFFF;
          opacity: 1;
          border-radius: 30px;
          text-align: center;
        }
      }
      &-file {
        width: 300px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 48px;
        color: #464646;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  &-send {
    width: 320px;
    height: 268px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
      box-sizing: border-box;
    &-s {
      box-sizing: content-box;
      // visibility: hidden;
      position: absolute;
      top: 5px;
      // opacity: 0;
      border: 0;
      letter-spacing: 30px;
      // z-index: 1;
      text-indent: 20px;
      height: 30px;
      width: 100%;
    }
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-text {
        height: 23px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #30586C;
        opacity: 1;
      }
      &-img {
        width: 20px;
        height: 20px;
      }
    }
    &-iphone {
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #30586C;
      margin-top: 8px;
    }
    &-codeBox {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 16px;
      height: 40px;
      position: relative;
      &-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        position: absolute;
        z-index: 1;
        background: #FFF;
      }
      &-i {
        width: 40px;
        height: 40px;
        background: #F6F7F8;
        opacity: 1;
        border-radius: 3px;
        text-align: center;
        line-height: 40px;
      }
    }
    &-time {
      flex: 1;
      box-sizing: border-box;
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-t {
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8B96A6;
        opacity: 1;
      }
      &-c {
        height: 12px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0067E1;
        text-decoration: underline;
        opacity: 1;
        flex: 1;
        text-align: right;
      }

    }
    &-btn {
      width: 100%;
      height: 44px;
      background: #0067E1;
      opacity: 1;
      border-radius: 22px;

      margin-top: 46px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #FFFFFF;
      opacity: 1;

      text-align: center;
    }
  }
  /deep/ .van-popup {
    box-shadow: 0px -7px 15px rgba(65, 191, 232, 0.3);
    border-radius: 12px 12px 0px 0px;
  }
  .sign-popup-iframe {
    width: 90vw;
    height: 70vh;
    // overflow-x: ;
    display: flex;
  }
  .inlineFrameExample {
    flex: 1;
  }
  /deep/.img-area {
    img {
      width: 100%;
    }
  }
  &-esign {
    width: 100%;
    height: 100%;
  }
}
</style>